import React from 'react'

const IndexPage = () => (
  <>
    <h1>H1 Tandelle 80/70</h1>
    <h2>H2 Tandelle 38/32</h2>
    <h3>H3 Seravek 18/20</h3>
    <h4>H4 Seravek 10/18</h4>
    <h5>H5 Seravek 14/21</h5>
  </>
)

export default IndexPage
